import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/images/header-logo.png'
import logo2 from '../../assets/images/crunchit-logo-header.png'
import MenuImg from '../../assets/images/Picture 2.jpg'

function Main() {

  const [active, setActive] = useState();
  const [Nav, setNav] = useState(false);
  const [Home, setHome] = useState();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);


  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleMenuItemClick = () => {
    closeMenu();
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <>
      {active &&
        <div className="menu-backdrop" style={{ opacity: "1", visibility: "visible" }}></div>
      }
      <header className={`fixed ${menuOpen ? 'menu-open' : ''}`}>
        <div className="container-fluid" style={{backgroundColor: 'black'}}>
          <nav className="navbar navbar-expand-lg">
            <Link className="navbar-brand" to="/">
              <img src={logo} alt="image" />
            </Link>
            <Link className="navbar-brand" to="/">
              <img id='logo2' src={logo2} alt="image" />
            </Link>
            <button className={`navbar-toggler ${menuOpen ? 'open' : ''}`} type="button" onClick={toggleMenu}>
              <span className="navbar-toggler-icon" onClick={() => setHome(Home === true ? false : true)}>
                <span className={`toggle-wrap ${menuOpen ? "active" : ""}`}>
                  <span className="toggle-bar"></span>
                </span>
              </span>
            </button>
            <div className={`collapse navbar-collapse ${menuOpen ? 'show' : ''}`} id="navbarSupportedContent">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link className="nav-link" to="/" onClick={handleMenuItemClick}>
                    Home
                  </Link>
                  {/* <span className="drp_btn">
                    <i className="icofont-rounded-down" />
                  </span>
                  <div className={`sub_menu ${isDropdownOpen ? 'open' : ''}`}>
                    <ul style={{ display: "block" }}>
                      <li>
                        <Link to="/">Home Defoult</Link>
                      </li>
                      <li>
                        <Link to="/home2">Home Slider Hero</Link>
                      </li>
                      <li>
                        <Link to="/home3">Home Dish List</Link>
                      </li>
                    </ul>
                  </div> */}
                </li>
                {/* <li className="nav-item has_dropdown">
                  <Link className="nav-link" to="#" onClick={handleMenuItemClick}>
                    MENU
                  </Link>
                  <span className="drp_btn">
                    <i className="icofont-rounded-down" />
                  </span>
                  <div className="sub_menu">
                    <ul>
                      <li>
                        <Link to="/menulist1">Menu List 1</Link>
                      </li>
                      <li>
                        <Link to="/menulist2">Menu List 2</Link>
                      </li>
                      <li>
                        <Link to="/menulist3">Menu List 3</Link>
                      </li>
                    </ul>
                  </div>
                </li> */}
                <li className="nav-item">
                  <Link className="nav-link" to="/menu" onClick={handleMenuItemClick}>
                    Menu
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/about" onClick={handleMenuItemClick}>
                    ABOUT US
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link className="nav-link" to="/ourchef" onClick={handleMenuItemClick}>
                    OUR CHEFS
                  </Link>
                </li>
                <li className="nav-item has_dropdown">
                  <Link className="nav-link" to="#" onClick={handleMenuItemClick}>
                    Pages
                  </Link>
                  <span className="drp_btn">
                    <i className="icofont-rounded-down" />
                  </span>
                  <div className="sub_menu">
                    <ul>
                      <li>
                        <Link to="/bloglist">Blog List </Link>
                      </li>
                      <li>
                        <Link to="/blogdetail">Blog Details</Link>
                      </li>
                      <li>
                        <Link to="/reservation1">reservation 1</Link>
                      </li>
                      <li>
                        <Link to="/reservation2">reservation 2</Link>
                      </li>
                      <li>
                        <Link to="/review">reviews</Link>
                      </li>
                      <li>
                        <Link to="/gallery">gallery</Link>
                      </li>
                    </ul>
                  </div>
                </li> */}
                <li className="nav-item">
                  <Link className="nav-link" to="/gallery" onClick={handleMenuItemClick}>
                    Gallery
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/contact" onClick={handleMenuItemClick}>
                    Contact
                  </Link>
                </li>
                <li className="nav-item contact_number">
                  <Link className="nav-link" to="tel:+18889282838">
                    <span>toll free :</span> +1 888 928-2838
                  </Link>
                </li>
                <li className="d-lg-none">
                  {/* <Link to="https://order.swiftbill.co/store/crunchitgrill" target="_blank" rel="noopener noreferrer" className="btn btn_primary" onClick={handleMenuItemClick}>
                    Order Online
                  </Link> */}
                  {/* <Link to="#" className="btn btn_primary" onClick={handleMenuItemClick}>
                    Coming Soon
                  </Link> */}
                </li>
              </ul>
            </div>

            <div className="action_bar">
              {/* <Link to="https://order.swiftbill.co/store/crunchitgrill" target="_blank" rel="noopener noreferrer" className="btn btn_primary" onClick={handleMenuItemClick}>
                Order Online
              </Link> */}
              {/* <Link to="#" className="btn btn_primary" onClick={handleMenuItemClick}>
                Coming Soon
              </Link> */}
              <div className="bar" onClick={() => setNav(true)}>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </nav>
        </div>
      </header>

      {Nav &&
        <div className={`right_sidemenu ${Nav && "active"}`}>
          <span className="close_rightmenu" onClick={() => setNav(false)}>
            <i className="icofont-close-circled" />
          </span>
          <div className="menu_inner_content">
            <Link to="#" className="side_logo">
              <img src={logo} alt="image" />
            </Link>
            <ul className="contact_listing">
              <li>
                <p className="sub_title">Call us</p>
                <div className="cnt_block">
                      <Link to="tel:+17088804107">Phone: (708) 880-4107</Link>
                      <span>
                        <i className="icofont-ui-call" />
                      </span>
                    </div>
                    <div className="cnt_block">
                      <Link to="tel:+17088801400">Phone: (708) 880-1400</Link>
                      <span>
                        {/* <i className="icofont-whatsapp" /> */}
                        <i className="icofont-ui-call" />
                      </span>
                    </div>
                    <div className="cnt_block">
                      <Link to="tel:+17084555088">Fax: (708) 455-5088</Link>
                      <span>
                        <i className="icofont-fax" />
                      </span>
                    </div>
                    <div className="cnt_block">
                      <Link to="tel:+18889282838">Toll Free: +1 888 928-2838</Link>
                      <span>
                        <i className="icofont-phone" />
                      </span>
                    </div>
              </li>
              {/* <li>
                <p className="sub_title">Email us</p>
                <div className="cnt_block">
                  <Link to="mailto:">crunchitgrill@gmail.com</Link>
                  <span>
                    <i className="icofont-envelope-open" />
                  </span>
                </div>
              </li> */}
              <li>
                <p className="sub_title">Find us</p>
                <div className="cnt_block">
                  <p>7725 W 159th St, Tinley Park, IL 60477</p>
                  <span>
                    <i className="icofont-location-pin" />
                  </span>
                </div>
              </li>
              <li>
                <p className="sub_title">Hours</p>
                <div className="cnt_block">
                  <p style={{marginBottom: 0}}>Monday &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 11 am–10 pm</p>
                  <span>
                    <i className="icofont-clock-time" />
                  </span>
                </div>
                <div className="cnt_block">
                  <p style={{marginBottom: 0}}>Tuesday&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 11 am–10 pm</p>
                  <span>
                    <i className="icofont-clock-time" />
                  </span>
                </div>
                <div className="cnt_block">
                  <p style={{marginBottom: 0}}>Wednesday &nbsp;&nbsp;&nbsp; 11 am–10 pm</p>
                  <span>
                    <i className="icofont-clock-time" />
                  </span>
                </div>
                <div className="cnt_block">
                  <p style={{marginBottom: 0}}>Thursday	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 11 am–10 pm</p>
                  <span>
                    <i className="icofont-clock-time" />
                  </span>
                </div>
                <div className="cnt_block">
                  <p style={{marginBottom: 0}}>Friday &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	11 am–12 pm</p>
                  <span>
                    <i className="icofont-clock-time" />
                  </span>
                </div>
                <div className="cnt_block">
                  <p style={{marginBottom: 0}}>Saturday &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	11 am–10 pm</p>
                  <span>
                    <i className="icofont-clock-time" />
                  </span>
                </div>
                <div className="cnt_block">
                  <p style={{marginBottom: 0}}>Sunday	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 11 am–10 pm</p> 
                  <span>
                    <i className="icofont-clock-time" />
                  </span>
                </div>
              </li>
            </ul>
            {/* <Link to="https://order.swiftbill.co/store/crunchitgrill" target="_blank" rel="noopener noreferrer" className="btn btn_primary">
              Order Online
            </Link> */}
            {/* <Link to="#" className="btn btn_primary">
              Coming Soon
            </Link> */}
            <figure className="tab_img">
              <img src={MenuImg} alt="img" />
            </figure>
          </div>
        </div>
      }
    </>
  )
}

export default Main