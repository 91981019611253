import React, { useState } from 'react'
import { Link } from 'react-router-dom'

function Main() {

  const [ytshow, setytShow] = useState(false);

  return (
    <>
      <footer>
        <div className="container">
          <div className="foote_widget contact_info">
            <div className="row py_50">
              <div className="col-md-7 direction_block">
                <p>
                  7725 W 159th St, Tinley Park,<br />
                  IL 60477<br />
                  {/* Toll Free : <Link to="tel:+18889282838">+1 888 928-2838</Link> */}
                </p>
                <Link to="/contact" className="btn_text">
                  get direction <i className="icofont-long-arrow-right" />
                </Link>
              </div>
              <div className="col-md-5 text-md-right timing_block">
                <p>
                  <a style={{ color: "white" }} href="tel:+17088804107">Phone: (708) 880-4107</a> <br />
                  <a style={{ color: "white" }} href="tel:+17088801400">Phone: (708) 880-1400</a> <br />
                  <a style={{ color: "white" }} href="tel:+17084555088">Fax: (708) 455-5088</a> <br />
                  <a style={{ color: "white" }} href="tel:+18889282838">Toll Free: +1 888 928-2838</a>
                </p>
                {/* <Link to="#" className="btn btn_primary mt-3" id="bookingForm_modelTitle" data-toggle="modal" data-target="#bookingForm_model" onClick={() => setytShow(true)}>
                  Order online
                </Link> */}
              </div>
              {/* <div className="col-md-5">
                <ul className="social_media">
                  <li>
                    <a href="https://www.facebook.com/profile.php?id=61552690529341" target='_blank' rel="noopener noreferrer">Facebook</a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/crunchitgrill" target='_blank' rel="noopener noreferrer">Instagram</a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
          <div className="foote_widget newsletter_block">
            <div className="row py_50">
              <div className="col-md-7 offer_block">
              {/* <Link to="https://order.swiftbill.co/store/crunchitgrill" target="_blank" rel="noopener noreferrer" className="btn btn_primary mt-3">
                Order Online
              </Link> */}
              {/* <Link to="#" className="btn btn_primary mt-3">
                Coming Soon
              </Link> */}
                <p style={{marginBottom: 0}}>Monday &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 11 am–10 pm</p>
                <p style={{marginBottom: 0}}>Tuesday&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 11 am–10 pm</p>
                <p style={{marginBottom: 0}}>Wednesday &nbsp;&nbsp;&nbsp; 11 am–10 pm</p>
                <p style={{marginBottom: 0}}>Thursday	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 11 am–10 pm</p>
                <p style={{marginBottom: 0}}>Friday &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	11 am–12 pm</p>
                <p style={{marginBottom: 0}}>Saturday &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	11 am–10 pm</p>
                <p style={{marginBottom: 0}}>Sunday	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 11 am–10 pm</p>
              </div>
              <div className="col-md-5">
                <ul className="social_media">
                  <li>
                    <a href="https://www.facebook.com/profile.php?id=61552690529341" target='_blank' rel="noopener noreferrer">Facebook</a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/crunchitgrill" target='_blank' rel="noopener noreferrer">Instagram</a>
                  </li>
                  <li>
                    <a href="https://www.tiktok.com/@crunchit.grill" target='_blank' rel="noopener noreferrer">TikTok</a>
                  </li>
                  {/* <li>
                    <a href="https://www.snapchat.com/" target='_blank' rel="noopener noreferrer">Snapchat</a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
          <div className="foote_widget menu_links">
            <div className="row">
              <div className="col-md-12">
                <ul className="links d-flex justify-content-center">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  {/* <li>
                    <Link to="/menulist1">Menu 1</Link>
                  </li> */}
                  <li>
                    <Link to="/menu">Menu</Link>
                  </li>
                  {/* <li>
                    <Link to="/menulist3">Menu 3</Link>
                  </li> */}
                  <li>
                    <Link to="/about">About us</Link>
                  </li>
                  {/* <li>
                    <Link to="/ourchef">Our chefs</Link>
                  </li> */}
                  <li> 
                    <Link to="/gallery">Gallery</Link>
                  </li> 
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 copy_right text-center">
              <p>
                © 2023 CrunchIt Grill. All Rights Reserved
              </p>
            </div>
          </div>
        </div>
        <div className="go_top" id="Gotop">
          <span>
            <i className="icofont-rounded-up" />
          </span>
        </div>
      </footer>
      {ytshow &&
        <div className="modal fade booking_model show" id="bookingForm_model" tabIndex={-1} aria-labelledby="bookingForm_modelTitle" style={{ display: "block", paddingRight: 17 }} aria-modal="true" role="dialog" >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header text-center border-0 d-block p-0">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setytShow(false)}>
                  <span aria-hidden="true">
                    <i className="icofont-close-line" />
                  </span>
                </button>
                <h2 className="mb-2">Online Reservation</h2>
                <p>
                  Booking request <a href="tel:+88123123456">+88-123-123456</a> or fill
                  out the order form
                </p>
              </div>
              <div className="modal-body">
                <div className="booking_form">
                  <form>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input type="text" className="form-control" placeholder="Your Name" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input type="text" className="form-control" placeholder="Phone Number" />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <span className="icon">
                            <i className="icofont-user-alt-7" />
                          </span>
                          <input type="text" className="form-control has_icon" placeholder="1 Person" />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <span className="icon">
                            <i className="icofont-calendar" />
                          </span>
                          <input type="date" className="form-control  flex-row-reverse" />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <span className="icon">
                            <i className="icofont-clock-time" />
                          </span>
                          <input type="time" className="form-control has_icon  flex-row-reverse pl-0"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            placeholder="Message"
                            defaultValue={""}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <button className="btn btn_primary w-100 ">book a table</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default Main